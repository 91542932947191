<template>
  <div class="container-like">
    <div class="content-like">
      <div class="section-matrix-like" v-for="(item, idx) in question.subjects" :key="idx">
        <div class="section-label">
          <span :style="colors.splashColor" v-html="replaceMeta(item.label)"></span>
        </div>
        <div class="section-rating">
          <div class="content-rating">
              <app-form-rate-active
                v-model="inputValue"
                :items="items"
                item-value="id"
                @rate-value="updateValue($event, item._id)"
              />
              <i class="material-icons up">thumb_up</i>
              <i class="material-icons down">thumb_down</i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { replaceMetadata } from '../../_helpers/metadata'

export default {
  name: 'like',
  props: ['question', 'colors'],
  components: {
    'app-form-rate-active': () => import('@binds-tech/binds-design-system/src/components/Form/RateActive')
  },
  data () {
    return {
      inputValue: '',
      value: [],
      items: [
        { id: 1, value: 0 },
        { id: 2, value: 100 }
      ]
    }
  },
  mounted () {
    if (this.question.language) {
      this.$i18n.locale = this.question.language
    }
    this.$root.$on('footer-submit', payload => {
      if (payload) {
        this.submit()
      }
    })
  },
  methods: {
    updateValue (rating, id) {
      const optionIndex = this.value.findIndex(item => item._id === id)
      if (optionIndex > -1) {
        this.value[optionIndex].rating = rating
      } else {
        this.value.push({
          _id: id,
          rating
        })
      }
      if (this.question.required && (this.value.length === this.question.subjects.length)) {
        this.$root.$emit('show-btn', true)
      }
    },
    submit () {
      const send = this.value
      let submitSubjects = {}
      send.length > 0 ? submitSubjects = { value: send } : submitSubjects = { skip: true }
      this.$emit('value-changed', submitSubjects)
      this.$emit('question-answered', true)
      this.$root.$emit('refresh-footer')
    },
    replaceMeta (str) {
      return replaceMetadata(str, this.metadata, this.answersList)
    }
  },
  computed: {
    questionsList () {
      const qs = this.$store.getters['question/getQuestion']
      return qs
    },
    metadata () {
      let data
      if (!this.isPreview) {
        data = this.$store.getters['seedData/getSeedData']
      } else {
        data = this.$store.getters['preview/getSeedData']
      }

      const metadata = data && data.metadata ? data.metadata : {}
      return metadata
    }
  }
}
</script>

<style lang="scss">
.container-like {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .content-like {
    width: 95%;
    min-height: 100%;
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .section-matrix-like {
      border: 1px solid #e0e0e0;
      border-radius: 5px;
      padding: 4px;
      width: 100%;
      min-height: 50px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .section-label {
        width: 65%;
        min-height: 100%;
        padding-left: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          color: var(--splashColor);
        }
      }
      .section-rating {
        width: 35%;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .content-rating {
          position: relative;
          height: 80%;
          width: 70%;
          border-radius: 35px;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.26);
          border: 1px solid #cacaca;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          .rating {
            z-index: 10;
            width: 100%;
            height: 100%;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .rating__item:nth-child(2) {
              width: 50%;
              height: 100%;
              border-radius: 35px 0 0 35px;
              .rating__button {
                width: 100%;
                border-radius: 35px 0 0 35px;
                height: 100%;
                background: transparent !important;
              }
              .rating__button:hover {
                background: #ea492240 !important;
              }
              .active-rate {
                transform: scale(1) !important;
                border: none !important;
                background: #ea492240 !important;
              }
            }
            .rating__item:nth-child(5) {
              width: 50%;
              height: 100%;
              border-radius: 0 35px 35px 0;
              .rating__button {
                border-radius: 0 35px 35px 0;
                border-left: 1px solid #b6b6b6;
                width: 100%;
                height: 100%;
                background: transparent !important;
              }
              .rating__button:hover {
                background: #51ad5940 !important;
              }
              .active-rate {
                transform: scale(1) !important;
                border: none !important;
                background: #51ad5940 !important;
              }
            }
          }
          .up {
            position: absolute;
            right: 25px;
            color: #51ad59;
          }
          .down {
            position: absolute;
            left: 25px;
            color: #ea4922;
          }
        }
      }
    }
  }
}

// responsivity for mobile
@media only screen and (max-width: 850px) {
  .container-like {
    .content-like {
      .section-matrix-like {
        .section-rating {
          .content-rating {
            width: 95%;
            .up {
              right: 22px;
            }
            .down {
              left: 22px;
            }
          }
        }
      }
    }
  }
}
</style>
